import React, {Component} from 'react';
import { graphql } from 'gatsby';
import Links from '../utils/links';

/**
 *
 */
export default class PathToCollegeCard extends Component {

    /**
     * Constuct components manager
     * @param props
     */
    constructor(props) {
        super(props);
        this.data = props;
    }


    renderButton()
    {
        if(this.data.links.length === 0)
        {
            return null;
        }

        const link = this.data.links[0];
        if (this.props.field_button_color === "Black") {
          return (
            Links.renderLink(link, "btn btn-lg btn-dark")
          )
        } else {
          return (
            Links.renderLink(link, "btn btn-lg btn-blue")
          )
        }
    }

    renderImage()
    {

        if(this.data && this.data.relationships && this.data.relationships.field_image && this.data.relationships.field_image.localFile){
            return (<img src={this.data.relationships.field_image.localFile.publicURL} alt="college card" />);
        }
        return '';
        
    }

    /**
     * Render menu
     * @returns {*}
     */
    render()
    {
      let innerClass = "college-card-inner"
      if (this.props.field_color_of_svg === 'Blue') {
            innerClass += ' rule-blue'
          } else if (this.props.field_color_of_svg === 'Purple') {
            innerClass += ' rule-purple'
          } 
        return (
            <section className="college-card">
                <div className={innerClass}>
                  {this.renderImage()}
                  <h2>{this.data.field_title}</h2>
                  {this.data.field_text && (<div dangerouslySetInnerHTML={{__html: this.data.field_text.processed}} />)}
                  {this.renderButton()}
                </div>    
            </section>
        )
    }
};

export const PathToCollegeCardQuery = graphql`
fragment ParagraphPathToCollegeCard on paragraph__path_to_college_card {
  field_title
  field_color_of_svg
  field_button_color
  field_text {
	processed
  }
  
  links {
    alias
    title
    fieldname
  }
  
  relationships {
	field_image {
	  localFile {
          publicURL
	  }
	}
  }
}
`;
